
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Pagination, Row } from "react-bootstrap";
import { Search, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { IMetadataOption } from "../../../entities/metadata.entity";
import { IProfessionalEmailEntity } from "../../../entities/professional.entity";
import { getProfessionalsEmail, IGetProfessionalsEmailFilter, IGetProfessionalsEmailPagination, IGetProfessionalsEmailSorting} from "../../../redux/slices/email.slice";
import { AppDispatch, RootState } from "../../../redux/store";
import { AdminProfessionalEmailListOrderBy } from "../../../utils/professionals-default";
import PaginationLimit from "../dropdown/list/PaginationLimit";
import Sorting from "../dropdown/list/Sorting";
import PageSelect from '../dropdown/list/PageSelect';

interface IProps {
    onSelect?: (professional: IProfessionalEmailEntity) => void;
};

const SearchProfessionalEmailList: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const professionalsEmail: Array<IProfessionalEmailEntity> = useSelector((s: RootState) => s.professionalsEmail.value);
    const countTotalProfessionals: number = useSelector((s: RootState) => s.professionalsEmail.total);

    const [filter, setFilter] = useState<IGetProfessionalsEmailFilter>({ text: '', statoIscrizione: 'iscritto' });
    const [pagination, setPagination] = useState<IGetProfessionalsEmailPagination>({ page: 1, limit: 10 });
    const [sorting, setSorting] = useState<IGetProfessionalsEmailSorting>({ orderby: Array<string>(), ordertype: false });
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textAreaSearch, setTextAreaSearch] = useState<string>('');

    const columns: TableColumn<IProfessionalEmailEntity>[] = [
        {
            cell: (row, index, column, id) => {
                return (<Button variant="primary" size="sm" className={`my-1`} onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    if (props.onSelect) {
                        props.onSelect(row);
                    }
                }} >seleziona</Button>);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Professionista',
            selector: (row: IProfessionalEmailEntity) => (row.cognome ? row.cognome.toUpperCase() : '') + ' ' + row.nome,
        },
        {
            id: 'codiceFiscale',
            name: 'Codice Fiscale',
            selector: (row: IProfessionalEmailEntity) => row.codiceFiscale,
        },
        {
            id: 'email',
            name: 'Email',
            selector: (row: IProfessionalEmailEntity) => row.email ? row.email.toLowerCase() : '',
        },
    ] as TableColumn<IProfessionalEmailEntity>[];

    const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
        setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleSortingOrderType = (type: boolean) => {
        setSorting({ ...sorting, ordertype: type });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handlePaginationLimit = (limit: number) => {
        setPagination({ page: 1, limit: limit });
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProfessionalsEmail({ filter: filter, pagination: pagination, sorting: sorting }))
            .unwrap()
            .then((res) => {
                const payload = res.payload as unknown as Array<IProfessionalEmailEntity>;
                console.log("trovati tot inadempienti: " + (payload?.length ? payload.length : 0) + " su " + countTotalProfessionals + " totali");
                console.log('getProfessionalsEmail: ', payload);
                // setInitialValues(payload.profile);
              })
            .finally(() => setIsLoading(false));
    }, [dispatch, filter, pagination, sorting]);

    const handlePaginationLoad = (pag: number) => {
        setPagination({ ...pagination, page: pag });
    };

    const handlePaginationLoadMore = () => {
        setPagination({ ...pagination, page: pagination.page + 1 });
    };

    const handlePaginationLoadMinus = () => {
        const previuosPage = pagination.page - 1;
        if (previuosPage === 0) return;
        setPagination({ ...pagination, page: pagination.page - 1 });
    };

    const handleFilterByText = () => {
        setFilter({ ...filter, text: textAreaSearch });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setTextAreaSearch(text);
    };

    const handleClearFilterByText = () => {
        setTextAreaSearch('');
        setFilter({ ...filter, text: '' });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    return (
        <Fragment>
            <Container fluid as="section" className="m-0 p-0">
                <Row className="p-2 bg-light">
                    <Col>
                        <InputGroup>
                            <FormControl placeholder="Cerca digitando: codice fiscale, cognome e/o nome, email e premi INVIO..." id="idTextSearch"
                                onChange={handleChangeText}
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleFilterByText();
                                    }
                                }} />
                            <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        element.value = '';
                                    }
                                    handleClearFilterByText();
                                }}><X size={20} /></InputGroup.Text>
                            <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    handleFilterByText();
                                }}><Search size={20} /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                {/* <Row className="px-2 pt-1 pb-2 bg-light">
                    <Col>
                        <InputGroup className="d-flex justify-content-end">
                            <StatoIscrizione onSelect={handleFilterByStatoIscrizione} defaultSelectedId={'iscritto'} />
                            <div className={`${props.disableFilterByExt ? 'd-none' : ''}`}>
                                <Esterno onSelect={handleFilterByEsterno} defaultSelectedId={!props.disableFilterByExt} />
                            </div>
                        </InputGroup>
                    </Col>
                </Row> */}
                <Row className="px-2 pt-1 pb-2 bg-light mb-1">
                    <Col className="my-1">
                        {(professionalsEmail.length > 0 ? "professionisti inadempienti " + (1 + pagination.limit * (pagination.page-1)) + "-" + (professionalsEmail.length + pagination.limit * (pagination.page-1)) + 
                                (professionalsEmail.length >= countTotalProfessionals ? "" : " di " + countTotalProfessionals) : "0 professionisti inadempienti TOTALI")}
                    </Col>
                    <Col>
                        <InputGroup className="d-flex justify-content-end">
                            <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalProfessionals / pagination.limit)} onSelect={handlePaginationLoad} />
                            <Sorting initSortingOptions={AdminProfessionalEmailListOrderBy} onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType} />
                            <PaginationLimit enableUnlimit={false} onSelect={handlePaginationLimit} defaultSelectedId={10} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable columns={columns} data={professionalsEmail}
                            responsive striped dense
                            noDataComponent="Nessun risultato"
                            progressPending={isLoading} />
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col className="text-center">
                        <Pagination className="d-flex justify-content-center">
                            <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First>  
                            <Pagination.Prev key={"idPaginationItemPrev"} onClick={handlePaginationLoadMinus} disabled={(pagination.page - 1) === 0}>Precedente</Pagination.Prev>
                            <Pagination.Item key={pagination.page} onClick={() => handlePaginationLoad(pagination.page)}>{pagination.page}</Pagination.Item>
                            <Pagination.Next key={"idPaginationItemNext"} onClick={handlePaginationLoadMore} disabled={pagination.limit != professionalsEmail.length}>Successiva</Pagination.Next>
                            <Pagination.Last onClick={() => handlePaginationLoad(Math.ceil(countTotalProfessionals / pagination.limit))} disabled={pagination.limit != professionalsEmail.length}>Ultima pagina</Pagination.Last>
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default SearchProfessionalEmailList;