import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { IRejectResponse } from '../../api/api.interface';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { IPostSubscriberThunkPayload, postSubscriber } from '../../redux/slices/course.slice';
import { AppDispatch } from '../../redux/store';
import { professionalInit } from '../../utils/professionals-default';
import SearchProfessionalList from '../shared/search/SearchProfessionalList';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IProps {
    idCorso: number;
};

interface IForm extends IPostSubscriberThunkPayload {
    professional: IProfessionalEntity | null;
}


const SubscriberAddForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const dataInizio: Date | undefined = useSelector((s: RootState) => s.course?.value?.dataInizio?new Date(s.course.value.dataInizio) : undefined);
    
    const today = new Date();
    const schema = yup.object().shape({
        codiceFiscale: yup.string().required("Campo obbligatorio").min(16, "Campo obbligatorio"),
        dtConfReg: yup.date().nullable().required("Campo obbligatorio").max(today, "La data di conferma registrazione non può essere nel futuro"),
        dtConfPres: yup.date().nullable().max(today, "La data di conferma presenza non può essere nel futuro")
    });

    const [fields, setFields] = useState({
        idCorso: props.idCorso,
        codiceFiscale: '',
        esterna: false,
        professional: null,
        dtConfReg: dataInizio,
        dtConfPres: dataInizio,
    } as IForm);

    const [isLoading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<IRejectResponse>();

    const handleSubmit = (value: IForm) => {
        setLoading(true);
        dispatch(postSubscriber(value as IPostSubscriberThunkPayload))
            .unwrap()
            .then(() => setSubmitted(true))
            .catch((e) => setError(e))
            .finally(() => setLoading(false));
    };

    const handleSelect = (professional: IProfessionalEntity, ext: boolean) => {
        setFields({
            ...fields,
            codiceFiscale: professional.codiceFiscale,
            esterna: ext,
            professional: professional
        });
    };

    const handleReset = () => {
        setFields({
            ...fields,
            codiceFiscale: '',
            esterna: false,
            professional: null,
        });
    };

    return (
        <Fragment>
            {submitted ? (
                <div className="text-center">
                    <CheckCircleFill size={94} className="text-success mb-5" />
                    <h3 className="text-success">Operazione completata!</h3>
                </div>
            ) : (
                error ? (
                    <div className="text-center" >
                        {error.error}
                    </div>
                ) : (
                    <Fragment>
                        {
                            fields.professional === null ? (
                                <Fragment>
                                    {/* <p><a href='#' onClick={() => handleSelect(professionalInit, false)}>Clicca QUI</a> per inserire il professionista manualmente</p> */}
                                    <SearchProfessionalList onSelect={handleSelect} showDetail={false} disableFilterByExt={false} />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {/* <p><a href='#' onClick={() => handleReset()}>Clicca QUI</a> per cercare il professionista dalla lista</p> */}
                                    <Formik initialValues={fields} validationSchema={schema} onSubmit={handleSubmit}>
                                        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, }) => (
                                            <Form noValidate onSubmit={handleSubmit} >
                                                {
                                                    (fields.professional && fields.professional.idProfessionista > 0) ? (
                                                        <Fragment>
                                                            <Form.Group className="mb-3" controlId="cognomenomeValidation">
                                                                <Form.Label>Cognome e nome</Form.Label>
                                                                <Form.Control type="text" value={fields.professional.cognome + ' ' + fields.professional.nome} disabled={true} />
                                                            </Form.Group>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment />
                                                    )
                                                }
                                                <Form.Group className="mb-3" controlId="codiceFiscaleValidation">
                                                    <Form.Label>Codice fiscale <Asterisk size={8} className="mb-3" /></Form.Label>
                                                    <Form.Control as="input" type="text" onChange={handleChange} max="16"
                                                        name="codiceFiscale" value={values.codiceFiscale} disabled={isLoading}
                                                        isInvalid={!!touched.codiceFiscale && !!errors.codiceFiscale} />
                                                    <Form.Control.Feedback type="invalid">{errors.codiceFiscale}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="esternaValidation">
                                                    <Form.Label>Tipo iscrizione <Asterisk size={8} className="mb-3" /></Form.Label>
                                                    <Form.Check type="switch">
                                                        <Form.Check.Input onChange={handleChange} disabled={isLoading}
                                                            name="esterna" defaultChecked={values.esterna}
                                                            isInvalid={!!touched.esterna && !!errors.esterna} />
                                                        <Form.Check.Label>esterno</Form.Check.Label>
                                                        <Form.Control.Feedback type="invalid">{errors.esterna}</Form.Control.Feedback>
                                                    </Form.Check>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="dtConfRegValidation">
                                                    <Form.Label as={Col} lg={3} md={4} sm={13}>Data iscrizione<Asterisk size={8} className="mb-3" /></Form.Label>
                                                    <Col lg={3} md={4} sm={13}>
                                                        <Form.Control type="date" onChange={handleChange}
                                                                name="dtConfReg" 
                                                                placeholder="Data iscrizione" 
                                                                defaultValue={dataInizio?.toISOString().substring(0,10)}
                                                                isInvalid={!!touched.dtConfReg && !!errors.dtConfReg}
                                                                />
                                                        <Form.Control.Feedback type="invalid">{errors.dtConfReg}</Form.Control.Feedback>
                                                    </Col>  
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="dtConfPresValidation">
                                                    <Form.Label as={Col} lg={3} md={4} sm={13}>Data presenza</Form.Label>
                                                    <Col lg={3} md={4} sm={13}>
                                                        <Form.Control type="date" onChange={handleChange}
                                                                name="dtConfPres" 
                                                                placeholder="Data presenza" 
                                                                defaultValue={dataInizio?.toISOString().substring(0,10)}
                                                                isInvalid={!!touched.dtConfPres && !!errors.dtConfPres}                                                   
                                                                />
                                                        <Form.Control.Feedback type="invalid">{errors.dtConfPres}</Form.Control.Feedback>
                                                    </Col>  
                                                </Form.Group>
                                                <Button type="submit" className="w-100" disabled={isLoading}>
                                                    {isLoading ? (
                                                        <Fragment>
                                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                                            <span className="ms-1">Attendere</span>
                                                            <span>...</span>
                                                        </Fragment>
                                                    ) : (
                                                        <span>Salva</span>
                                                    )}
                                                </Button>
                                            </Form>
                                        )}
                                    </Formik>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            )}
        </Fragment>
    );
};

export default SubscriberAddForm;