import { Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IAttachment } from '../../entities/attachment.entity';
import { IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPostCertificationThunkPayload, postCertification } from '../../redux/slices/certification.slice';
import { getMetadataCertification } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';
import { ICompanyEntity } from '../../entities/company.entity';

interface IFormValue extends IPostCertificationThunkPayload {
    responsabilita: boolean;
};

interface IProps {
    codiceFiscale: string;
    onSubmitSuccess?: () => void;
}

const CertificationFormCfpOnly: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const tipologiaCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipologia_certificazione);
    const azienda: ICompanyEntity | null = useSelector((s: RootState) => s.company.value);
    const corso = useSelector((s: RootState) => s.course.value || null);

    const [initValState, setInitValState] = useState<IFormValue>({
        codiceFiscale: props.codiceFiscale,
        idOggetto: 5,
        idTipologia: 1,
        annoRiferimento: new Date().getFullYear(),
        dataRiferimentoDa: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        dataRiferimentoA: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        titolo: '',
        organizzatore: '',
        codiceCorso: '',
        cfpDichiarati: 0,
        cfpAssegnati: 1,
        noteRichiedente: '',
        idStatoCertificazione: 1,
        responsabilita: false,
        allegati: new Array<IAttachment>(),
    });

    useEffect(() => {
        setInitValState({
            codiceFiscale: props.codiceFiscale,
            idOggetto: 5,
            idTipologia: 1,
            annoRiferimento: new Date(parseISOStringToStandardDate(corso?.dataInizio || null)).getFullYear(),
            dataRiferimentoDa: corso?.dataInizio || new Date().toISOString(),
            dataRiferimentoA: corso?.dataInizio || new Date().toISOString(),
            titolo: corso?.titolo || '',
            organizzatore: azienda?.nome || '',
            codiceCorso: corso?.codice || '',
            cfpDichiarati: 0,
            cfpAssegnati: 1,
            noteRichiedente: 'cfp assegnati dal consiglio di disciplina',
            idStatoCertificazione: 4,
            responsabilita: false,
            allegati: new Array<IAttachment>(),
        });
    }, [corso, azienda]);

    const schema = yup.object().shape({
        idTipologia: yup.number().required("Campo obbligatorio"),
        cfpAssegnati: yup.number().required("Campo obbligatorio").min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
            .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`),
        annoRiferimento: yup.date().required("Selezionare anno di riferimento").max(2025, 'non si può selezionare un triennio non ancora aperto'),
/*         dataRiferimentoDa: yup.date().required("Selezionare data inizio"),
        dataRiferimentoA: yup.date().required("Selezionare data fine"), */
    });

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [submittedConfirmed, setSubmittedConfirmed] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmittedConfirmed(true);
        if (props?.onSubmitSuccess) {
            props.onSubmitSuccess();
        };
    };

    useEffect(() => {
        dispatch(getMetadataCertification({}))
    }, [dispatch]);

    const handleSubmit = (value: IFormValue) => {
        setLoading(true);
        setError(false);        
        value.dataRiferimentoDa = parseISOStringToStandardDate(new Date(value.annoRiferimento, 0, 1).toISOString());
        value.dataRiferimentoA = parseISOStringToStandardDate(new Date(value.annoRiferimento, 11, 31).toISOString());
        dispatch(postCertification(value))
            .unwrap()
            .then(() => {
                setShow(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Fragment>
            <div className={`${submittedConfirmed ? 'd-none' : ''}`}>
                <Formik 
                    enableReinitialize={true}
                    initialValues={initValState}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <div className="mx-3">
                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Oggetto<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <p>Consiglio di disciplina</p>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idTipologia"
                                            onChange={handleChange}
                                            disabled={isLoading}
                                            isInvalid={!!touched.idTipologia && !!errors.idTipologia}
                                        >
                                            {tipologiaCertificazione.filter(item2 => item2.extId === 5).filter(item3 => item3.id > 0).map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idTipologia" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.idTipologia}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>CFP<Asterisk size={8} className="mb-3" />
                                    </Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            min={0}
                                            type="number"
                                            name="cfpAssegnati"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.cfpAssegnati}
                                            onChange={handleChange}
                                            isInvalid={!!touched.cfpAssegnati && !!errors.cfpAssegnati}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cfpAssegnati}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                               <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Anno di riferimento<Asterisk size={8} className="mb-3" />
                                    </Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="number"
                                            name="annoRiferimento"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.annoRiferimento}
                                            onChange={handleChange}
                                            isInvalid={!!touched.annoRiferimento && !!errors.annoRiferimento}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.annoRiferimento}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                {/* <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Periodo rif.<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={3} md={4} sm={12}>
                                        <Form.Control
                                            type="date"
                                            name="dataRiferimentoDa"
                                            disabled={isLoading}
                                            value={values.dataRiferimentoDa}
                                            onChange={handleChange}
                                            isInvalid={!!touched.dataRiferimentoDa && !!errors.dataRiferimentoDa}
                                        />
                                        <Form.Text>Inizio</Form.Text>
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimentoDa}</Form.Control.Feedback>
                                    </Col>
                                    <Col lg={3} md={4} sm={12}>
                                        <Form.Control
                                            type="date"
                                            name="dataRiferimentoA"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.dataRiferimentoA}
                                            onChange={handleChange}
                                            isInvalid={!!touched.dataRiferimentoA && !!errors.dataRiferimentoA}
                                        />
                                        <Form.Text>Fine</Form.Text>
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimentoA}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group> */}
                                {
                                    error ? (
                                        <Alert variant='danger' className="my-3">
                                            Si è verificato un errore durante il salvataggio della richiesta.
                                        </Alert>
                                    ) : (
                                        <Fragment />
                                    )
                                }

                                <Button type="submit" className="w-10" size="lg" variant="primary" disabled={isLoading || submittedConfirmed}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendi</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Invia</span>
                                    )}
                                </Button>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>

            <Modal show={show} centered>
                <Modal.Header >
                    <Modal.Title>Operazione completata con successo! </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <CheckCircleFill size={100} className="text-success" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CertificationFormCfpOnly;
