import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaEnvelope, FaGraduationCap, FaHome, FaUniversity } from 'react-icons/fa';
import { MdHelp, MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from '../../entities/user.entity';
import { AuthenticatedRoutes, AuthenticatedRoutesAdmin, UnauthenticatedRoutes } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  user: IUser;
}

const SidebarAdmin: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  let [selected, setSelected] = useState<string>(AuthenticatedRoutesAdmin.Dashboard);

  const isSelected = (key: string) => selected ? selected.startsWith(key) : false;
  const isSelectedItem = (key: string) => selected ? selected === key : false;

  const handleSelectParent = (e: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isSelectedItem(value) && expanded) return;
    if (!expanded) dispatch(openSidebar());
    if (isSelected(value)) return;
    let target: HTMLElement = e.target as HTMLElement;
    while (target.tagName.toLocaleLowerCase() !== 'div') {
      target = target.parentElement as HTMLElement;
    }
    setTimeout(() => target.click(), 200);
    setSelected(value);
  };

  const handleSelectItem = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = UnauthenticatedRoutes.Login));
  };

  return (
    <SideNav
      style={{ zIndex: 1031 }}
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelectItem}
    >
      <SideNav.Toggle />
      <SideNav.Nav selected={selected}>
        {expanded && (
          <NavItem
            eventKey={AuthenticatedRoutes.Me}
            className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
          >
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none"
              >
                <Image src="/assets/column-dark-theme.svg" height={72} />
              </Link>
            </div>
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none text-light text-uppercase small"
              >
                {props.user.firstName} {props.user.lastName}
              </Link>
            </div>
          </NavItem>
        )}
        <NavItem
          eventKey={AuthenticatedRoutesAdmin.Dashboard}
          className={isSelected(AuthenticatedRoutesAdmin.Dashboard) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesAdmin.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.Dashboard)}
              className="text-decoration-none"
            >
              <FaHome size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesAdmin.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.Dashboard)}
              className="text-decoration-none"
            >
              Home
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesAdmin.Courses}
          className={isSelected(AuthenticatedRoutesAdmin.Courses) && 'selected'}
        >
          <NavIcon
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Courses)}
          >
            <FaUniversity size={32} />
          </NavIcon>
          <NavText
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Courses)}
          >
            Corsi
          </NavText>
          <NavItem eventKey={AuthenticatedRoutesAdmin.PublishedCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.PublishedCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.PublishedCourses)}
                className="text-decoration-none text-light"
              >
                Pubblicati
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.UnpublishedCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.UnpublishedCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.UnpublishedCourses)}
                className="text-decoration-none text-light"
              >
                Non pubblicati
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.OtherCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.OtherCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.OtherCourses)}
                className="text-decoration-none text-light"
              >
                Altri ordini
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.ImportCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.ImportCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.ImportCourses)}
                className="text-decoration-none text-light"
              >
                Importa corsi
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.NewCourse} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.NewCourse}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.NewCourse)}
                className="text-decoration-none text-light"
              >
                Crea nuova istanza
              </Link>
            </NavText>
          </NavItem>
        </NavItem>
        {/* <NavItem
          eventKey={AuthenticatedRoutesAdmin.Teachers}
          className={isSelected(AuthenticatedRoutesAdmin.Teachers) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesAdmin.Teachers}
              onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.Teachers)}
              className="text-decoration-none"
            >
              <FaUserTie size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesAdmin.Teachers}
              onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.Teachers)}
              className="text-decoration-none"
            >
              Elenco docenti
            </Link>
          </NavText>
        </NavItem> */}        
        <NavItem
          eventKey={AuthenticatedRoutesAdmin.Professionals}
          className={isSelected(AuthenticatedRoutesAdmin.Professionals) && 'selected'}
        >
          <NavIcon
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Professionals)}
          >
            <FaGraduationCap size={32} />
          </NavIcon>
          <NavText
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Professionals)}
          >
            Status formativo iscritti
          </NavText>
          <NavItem eventKey={AuthenticatedRoutesAdmin.ListProfessionals} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.ListProfessionals}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.ListProfessionals)}
                className="text-decoration-none text-light"
              >
                Elenco iscritti
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.TrainingCreditsManage} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.TrainingCreditsManage}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.TrainingCreditsManage)}
                className="text-decoration-none text-light"
              >
                Esoneri e certificazioni
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.ImportProfessionals} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.ImportProfessionals}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.ImportProfessionals)}
                className="text-decoration-none text-light"
              >
                Importa certificati
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesAdmin.NewCertification} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.NewCertification}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.NewCertification)}
                className="text-decoration-none text-light"
              >
                Crea nuova certificazione
              </Link>
            </NavText>
          </NavItem>
        </NavItem>
        <NavItem eventKey={AuthenticatedRoutesAdmin.Communications}
          className={isSelected(AuthenticatedRoutesAdmin.Communications) && 'selected'}>
          <NavIcon
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Communications)}
          >
            <FaEnvelope size={26} />
          </NavIcon>
          <NavText
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesAdmin.Communications)}
          >
            Comunicazioni
          </NavText>
          <NavItem eventKey={AuthenticatedRoutesAdmin.MailInadempienti} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesAdmin.MailInadempienti}
                onClick={() => handleSelectItem(AuthenticatedRoutesAdmin.MailInadempienti)}
                className="text-decoration-none text-light"
              >
                Invio mail inadempienti
              </Link>
            </NavText>
          </NavItem>
        </NavItem>   
      </SideNav.Nav>
      <SideNav.Nav componentClass="footer">
        <NavItem
          eventKey={AuthenticatedRoutes.Support}
          className={isSelected(AuthenticatedRoutes.Support) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutes.Support}
              onClick={() => handleSelectItem(AuthenticatedRoutes.Support)}
              className="text-decoration-none"
            >
              <MdHelp size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutes.Support}
              onClick={() => handleSelectItem(AuthenticatedRoutes.Support)}
              className="text-decoration-none"
            >
              Supporto
            </Link>
          </NavText>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>Esci</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarAdmin;
