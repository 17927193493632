import { format } from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import ProfessionalsExportStatus from '../../components/shared/ProfessionalsExportStatus';
import SearchProfessionalList from '../../components/shared/search/SearchProfessionalList';
import SearchProfessionalEmailList from '../../components/shared/search/SearchProfessionalEmailList';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { getProfessionalsExport, IProfessionalsExportFulfilledPayload } from '../../redux/slices/professionals.slice';
import { AppDispatch } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';

const AdminProfessionals: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const handleShowModal = () => setShowModal(true);
    const handleHideModal = () => setShowModal(false);

    // const handleClickGetPaymentReceipt = (idCorso: number) => {        
    //     dispatch(getCoursePaymentReceipt({ idCorso: idCorso }))
    //     .unwrap()
    //     .then(() => { })
    //     .catch((err) => { 
    //         console.log("errore get ricevuta pagamento");
    //         const error = err as unknown as IRejectResponse;
    //         console.log(error);
    //         console.log(error.error);
    //         setErrorGetPaymentReceipt(error);
    //         setShowGetPaymentReceiptErrorModal(true);
    //     })
    //     .finally(() => {
    //         console.log("chiudo modal get ricevuta pagamento");
    //         handleCloseGetPaymentReceiptModal();
    //         setCountRefresh(countRefresh + 1);
    //     });
    // };

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        //dispatch(getCompany({}));
        //dispatch(getCompanies({}));
        //dispatch(getPartners({}));
        dispatch(getCompaniesOrder({}));
        //dispatch(getCompaniesPartner({}));
        dispatch(getMetadataCourse({}));
        //dispatch(getMetadataTeacher({}));
        dispatch(getMetadataCertification({}));
    }, [dispatch]);

    const handleClickItem = (professional: IProfessionalEntity, ext: boolean) => {
        //if (!ext) {
            let route = AuthenticatedRoutesAdmin.Professional.toString();
            route = route.replace(':cf', professional.codiceFiscale);
            navigate(route);
        //}
    };

    const handleExport = () => {
        setIsLoading(true);
        dispatch(getProfessionalsExport({}))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IProfessionalsExportFulfilledPayload;
                const div = document.getElementById("divProfessionalsExportAttachmentCSV");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            <PageTitle content="Professionisti">
                <a href="/assets/tutorial/O_ElencoProfessionisti.pdf" target="_blank" rel="noreferrer">
                    <FaInfoCircle className="text-dark" size={32} />
                </a>
            </PageTitle>
            <PageSubtitle content="Lista iscritti all'ordine">
                {isLoading ? (
                    <div className="d-flex">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ms-1">Attendere</span>
                        <span>...</span>
                    </div>
                ) : (
                    <div className="d-flex">
                        <Dropdown>
                            <Dropdown.Toggle variant="primary">
                                Esporta in .csv
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleExport}>Lista professionisti</Dropdown.Item>
                                <Dropdown.Item onClick={handleShowModal}>Verifica Amministrativa</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
                <div id="divProfessionalsExportAttachmentCSV" className="d-none"></div>
            </PageSubtitle>
            <PageParagraph content="" />
            <SearchProfessionalList onSelect={handleClickItem} showDetail={true} disableFilterByExt={true} />
            <Modal show={showModal} onHide={handleHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Report Status</h3><a href="/assets/tutorial/VerificaAmministrativa.legenda.v1.pdf" target="_blank" rel="noreferrer">
                            <FaInfoCircle className="text-dark" size={32} />
                        </a></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProfessionalsExportStatus />
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default AdminProfessionals;