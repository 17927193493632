import {
  Action,
  configureStore,
  ThunkAction
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import attachmentReducer from './slices/attachment.slice';
import attachmentsReducer from './slices/attachments.slice';
import authReducer from './slices/auth.slice';
import certificationReducer from './slices/certification.slice';
import certificationsReducer from './slices/certifications.slice';
import companiesReducer from './slices/companies.slice';
import companyReducer from './slices/company.slice';
import courseReducer from './slices/course.slice';
import coursesReducer from './slices/courses.slice';
import messageReducer from './slices/message.slice';
import metadataReducer from './slices/metadata.slice';
import professionalReducer from './slices/professional.slice';
import professionalsReducer from './slices/professionals.slice';
import statusFormativoReducer from './slices/statusformativo.slice';
import emailReducer from './slices/email.slice';
import emailsCountReducer from './slices/emailscount.slice';
import teacherReducer from './slices/teacher.slice';
import teachersReducer from './slices/teachers.slice';
import appuserReducer from './slices/appuser.slice';
import documentsReducer from './slices/documents.slice';
import uiReducer from './slices/ui.slice';
import favoritesReducer from './slices/favorites.slice';
import notificationsReducer from './slices/notifications.slice';
import advicesReducer from './slices/advices.slice';
import adviceReducer from './slices/advice.slice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: authReducer,
    metadata: metadataReducer,
    courses: coursesReducer,
    course: courseReducer,
    companies: companiesReducer,
    company: companyReducer,
    teachers: teachersReducer,
    teacher: teacherReducer,
    attachments: attachmentsReducer,
    attachment: attachmentReducer,
    message: messageReducer,
    professionals: professionalsReducer,
    professionalsEmail: emailReducer,
    professionalsEmailsCount: emailsCountReducer,
    professional: professionalReducer,
    statusFormativo: statusFormativoReducer,
    certifications: certificationsReducer,
    certification: certificationReducer,
    appuser: appuserReducer,
    documents: documentsReducer,
    favorites: favoritesReducer,
    notifications: notificationsReducer,
    advices: advicesReducer,
    advice: adviceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
